// Ant Pro Table Component

import type { ParamsType } from '@ant-design/pro-provider';
import { ProTable, ProTableProps } from "@ant-design/pro-components";
import enUSIntl from 'antd/es/locale/en_US';
import { ConfigProvider } from "antd";

const Table = <DataType extends Record<string, unknown>, Params extends ParamsType = ParamsType, ValueType = "text">(
  props: ProTableProps<DataType, Params, ValueType> & {
  defaultClassName?: string;
}) =>  <ConfigProvider locale={enUSIntl}><ProTable {...props} /></ConfigProvider>;

export default Table;